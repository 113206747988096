import React from "react";
import Layout from "@/components/layout";
import Partners from "@/components/partners"
import Support from "@/components/support"
import Editions from "@/components/editions"
import QuemPodeParticipar from "@/components/quem-pode-participar"
import Footer from "@/components/footer";
import AboutTwo from "@/components/about-two";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import SliderOne from "@/components/slider-one";
import SliderTwo from "@/components/slider-two";
import App from "@/components/app";
import Cronograma from "@/components/cronograma";
import ComoParticipar from "@/components/como-participar";
import Faq from "@/components/faq";


const HomeOne = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Vitalis Olimpíada de Medicina">
          <HeaderOne />
          <SliderOne />
          <Partners />
          <AboutTwo />
          <QuemPodeParticipar/>
          <ComoParticipar />
          {/* <SliderTwo /> */}
          <Editions/>
          <Cronograma />
          <App />
          <Faq />
          <Support />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default HomeOne;
